html,
body {
  margin: 0;
  padding: 0;
  background: #ff8a65;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 10%;
}

.App-logo {
  width: 100%;
}
